/* Reload page if it is outdated (from yesterday), but only if there are no forms */
if (document.querySelector('#main-content form') === null) {
  document.onvisibilitychange = function () {
    if (document.querySelector("meta[name=date]")) {
      if (document.querySelector("meta[name=date]").content !=
        (new Date()).toISOString().split('T')[0]) {
        window.location.reload(true);
        console.log("reloaded outdated page")
      }
    }
  }
}